import moment from 'moment';

export const generateDatesFromSunday = (days: number): string[] => {
  const dates = [];
  const now = moment();
  const currentDayOfWeek = now.day();
  const startOfWeek = now.clone().subtract(currentDayOfWeek, 'days');
  for (let i = 0; i < days; i++) {
    dates.push(startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD'));
  }

  return dates;
};

// export const generateDatesFromSunday = (): string[] => {
//   const dates = [];
//   const now = moment();
//   const currentDayOfWeek = now.day();
//   const startOfWeek = now.clone().subtract(currentDayOfWeek, 'days');
//   const endOfMonth = now.clone().endOf('month');
//   const daysInMonth = endOfMonth.date();

//   let currentDay = startOfWeek;

//   while (currentDay.isSameOrBefore(endOfMonth, 'day')) {
//     dates.push(currentDay.format('YYYY-MM-DD'));
//     currentDay.add(1, 'day');
//   }

//   // If the last day of the month is reached, add dates from the next month to ensure a full week is displayed
//   const daysToAdd = 6 - endOfMonth.day();
//   for (let i = 1; i <= daysToAdd; i++) {
//     dates.push(endOfMonth.clone().add(i, 'days').format('YYYY-MM-DD'));
//   }

//   return dates;
// };


export const generateDatesForMonth = (year: number, month: number): string[] => {
  const dates = [];
  const startOfMonth = moment({ year, month }).startOf('month');
  const endOfMonth = startOfMonth.clone().endOf('month');
  const daysInMonth = endOfMonth.date();

  for (let i = 0; i < daysInMonth; i++) {
    dates.push(startOfMonth.clone().add(i, 'days').format('YYYY-MM-DD'));
  }

  // Ensure the array has 31 days by adding days from the next month if necessary
  if (daysInMonth < 31) {
    const daysToAdd = 31 - daysInMonth;
    for (let i = 0; i < daysToAdd; i++) {
      dates.push(endOfMonth.clone().add(i + 1, 'days').format('YYYY-MM-DD'));
    }
  }

  return dates;
};
