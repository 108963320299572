import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom, faWrench } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { PropertyList, ImagesResponse } from '../../api/type';
import moment from 'moment';
import { fetchImages } from '../../api/request';
import Accordion from '../dropdown/Accordion';


import cleaningIcon from '../assets/cleaning-dark.png';
import maintenanceIcon from '../assets/maintenance-2.png';
import improvementIcon from '../assets/settings.png';
import taskIcon from '../assets/file.png';
import contactIcon from '../assets/phone.png';
import xIcon from '../assets/x.png';


interface Job {
  id: string;
  jobType: 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';
  status: string;
  date: string;
  startDate: string;
  endDate: string;
  duration: string;
}

interface MoreDetailSidebarProps {
  onClose: () => void;
  sideBar: boolean;
  jobs: Job[];
  property: PropertyList | undefined;
}



const MoreDetailSidebar: React.FC<MoreDetailSidebarProps> = ({ onClose, sideBar, jobs, property }) => {
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);



  useEffect(() => {
    const loadImages = async (jobId: string) => {
      try {
        const response = await fetchImages(jobId);
        setImages(response.data || []);
      } catch (error) {
        setError('Error fetching images.');
      } finally {
        setLoading(false);
      }
    };

    if (jobs.length > 0) {
      loadImages(jobs[0].id); // Fetch images for the first job initially
    }
  }, [jobs]);

  const jobTypeIcons: Record<'cleaning' | 'maintenance', IconDefinition> = {
    cleaning: faBroom,
    maintenance: faWrench,
  };

  return (
    <div className={`sidebar ${sideBar ? 'open' : ''}`}>
      <div className="sidebar-header">
        <h2>More Job Details</h2>
        <div className="sidebar-header-icons">
          <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className='x-icon' /></button>
        </div>
      </div>
      <div className="sidebar-body">
        <div className="sidebar-content-more-job">
          {jobs.map((job, index) => {
            const startTime = moment(job.startDate);
            const endTime = moment(job.endDate);
            const duration = moment.duration(endTime.diff(startTime));
            const durationHours = Math.floor(duration.asHours());
            const durationMinutes = duration.minutes();

            const getJobIcon = (jobType: string) => {
              switch (jobType) {
                case 'Cleaning':
                  return cleaningIcon;
                case 'Maintenance':
                  return maintenanceIcon;
                case 'Task':
                  return taskIcon;
                case 'Improvement':
                  return improvementIcon;
                case 'Contact':
                  return contactIcon;
                default:
                  return improvementIcon;
              }
            };

            return (
              <Accordion key={index} title={job.jobType} icon={getJobIcon(job.jobType)}>
                <div className='booking-row-second'>
                  <div className='booking-column'>
                    <div className="booking-label">Status</div>
                    <div className="booking-value ongoing">Ongoing</div>
                  </div>
                </div>

                <div className='booking-row'>
                  <div className='booking-column'>
                    <div className="booking-label">Date</div>
                    <div className="booking-value">{moment(job.startDate).format('MMM D, YYYY')}</div>
                  </div>
                </div>

                <div className='booking-row'>
                  <div className='booking-column'>
                    <div className="booking-label">Time Started</div>
                    <div className="booking-value">{moment(job.startDate).format('h:mm A')}</div>
                  </div>
                </div>

                <div className='booking-row'>
                  <div className='booking-column'>
                    <div className="booking-label">Time Finished</div>
                    <div className="booking-value">{moment(job.endDate).format('h:mm A')}</div>
                  </div>
                </div>

                <div className='booking-row-second'>
                  <div className='booking-column'>
                    <div className="booking-label">Duration</div>
                    <div className="booking-value">{`${durationHours} hour${durationHours !== 1 ? 's' : ''} ${durationMinutes} minute${durationMinutes !== 1 ? 's' : ''}`}</div>
                  </div>
                </div>

                <div className='property-row'>
                  <div className="property">
                    <img src={property?.image || "https://via.placeholder.com/50"} alt={property?.name || 'Property'} />
                    <div>
                      <h3>{property?.name}</h3>
                      <p>{property?.address}</p>
                    </div>
                  </div>
                </div>

                <div className='attachments'>
                  <h3>Attachments</h3>
                  {loading ? (
                    <div>Loading...</div>
                  ) : error ? (
                    <div>{error}</div>
                  ) : images.length > 0 ? (
                    <div className='attachment-list'>
                      {images.map((image, imgIndex) => (
                        <div className='attachment' key={imgIndex}>
                          <img src={image} alt="Attachment" />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='booking-label'>No Attachments Found</div>
                  )}
                </div>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MoreDetailSidebar;
