import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { fetchReservationDetails } from '../../api/request'; // Ensure this is correctly imported from your API request file
import { PropertyBedTypeCounts, PropertyList, ReservationDetails } from '../../api/type';
import { get } from '../../api/methods'; // Import the get method to fetch amenities
import calendarLight from '../assets/calendar.png';
import calendarDark from '../assets/calendar-dark.png';
import homeLight from '../assets/home.png';
import homeDark from '../assets/home-dark.png';
import userLight from '../assets/user.png';
import userDark from '../assets/user-dark.png';
import inboxLight from '../assets/inbox.png';
import inboxDark from '../assets/inbox-dark.png';
import checkCircle from '../assets/check-circle.png';
import clock from '../assets/clock.png';
import faceHappy from '../assets/face-happy.png';
import backIcon from '../assets/back.png';
import linkIcon from '../assets/link.png';
import kingBedIcon from '../assets/king.png';
import queenBedIcon from '../assets/queen.png';
import doubleBedIcon from '../assets/double.png';
import bunkBedIcon from '../assets/bunk.png';
import singleBedIcon from '../assets/single.png';
import xIcon from '../assets/x.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faUser,
  faClock,
  faHome,
  faWifi,
  faBathtub,
  faInbox,
  faTv,
  faCoffee,
  faSpinner,
  faCheckCircle,
  faTimesCircle,
  faHotel
} from '@fortawesome/free-solid-svg-icons';

import airbnbImage from '../assets/airbnb.png';
import ownerBlockImage from '../assets/ownerblock.png';
import bookingImage from '../assets/booking.png';
import directBookingImage from '../assets/direct.png';

interface BookingSidebarProps {
  reservationId: string | undefined;
  onClose: () => void;
  sideBar: boolean;
  propertyPassed: PropertyList | undefined;
  guestName: string | undefined;
  isManualBlock: boolean | undefined;
  isAdvanceNotice: boolean | undefined;
  isAfterBlock: boolean | null;
  arrivalDate: string | undefined;
  departureDate: string | undefined;
}

interface Amenity {
  id: string;
  name: string;
  imageUrl: string;
}

const channel = {
  airbnb: 'Airbnb',
  booking: 'Booking.com',
  direct: 'Direct',
  manual: 'Manual Block',
  owner: 'Owners Block'
} as const;

const getImageForBookingChannel = (type: string) => {
  switch (type) {
    case 'Airbnb':
      return airbnbImage;
    case 'Owners Block':
      return ownerBlockImage;
    case 'Booking.com':
      return bookingImage;
    case 'direct':
      return directBookingImage;
    default:
      return directBookingImage;
  }
};


const BookingSidebar: React.FC<BookingSidebarProps> = ({ reservationId, onClose, sideBar, propertyPassed, guestName, isManualBlock, isAdvanceNotice, isAfterBlock, arrivalDate, departureDate }) => {
  const [activeTab, setActiveTab] = useState('booking');
  const [activeInboxTab, setActiveInboxTab] = useState('scheduled');
  const [reservationData, setReservationData] = useState<ReservationDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [messageStatuses, setMessageStatuses] = useState<{ [key: string]: boolean }>({});
  const [amenities, setAmenities] = useState<Amenity[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (reservationId) {
        setLoading(true);
        try {
          const data = await fetchReservationDetails(reservationId);
          if (data && data.data) {
            setReservationData(data.data);

            // Initialize message statuses if automationMessages is present
            if (data.data.automationMessages) {
              const initialStatuses = data.data.automationMessages.reduce((acc, message) => {
                acc[message.id] = message.mailSent;
                return acc;
              }, {} as { [key: string]: boolean });
              setMessageStatuses(initialStatuses);
            }
          } else {
            setReservationData(null);
            setMessageStatuses({});
          }
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [reservationId]);

  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const response = await get('api/v1/amenities/all');
        if (Array.isArray(response.data)) {
          setAmenities(response.data);
        } else {
          console.error('Amenities data is not an array:', response.data);
          setAmenities([]);
        }
      } catch (error) {
        console.error('Error fetching amenities:', error);
        setAmenities([]);
      }
    };

    fetchAmenities();
  }, []);

  const getInitials = (name: string) => {
    const names = name.split(' ');
    const initials = names.map((n) => n[0]).join('');
    return initials.toUpperCase();
  };

  const handleToggleMessageStatus = (messageId: string) => {
    setMessageStatuses(prevStatuses => ({
      ...prevStatuses,
      [messageId]: !prevStatuses[messageId],
    }));
  };

  const scheduledMessages = reservationData?.automationMessages?.filter(message => message.triggerType === 'Check Out') || [];
  const historyMessages = reservationData?.automationMessages?.filter(message => message.triggerType !== 'Check Out') || [];

  const handleRedirect = (code: string) => {
    window.open(`/reservations/${code}`, '_blank', 'noopener,noreferrer');
  };


  const renderScheduleMessages = (messages: Array<any>) => (
    <div className="inbox-content">
      {messages.length === 0 ? (
        <div className="extrainfo-row">
          <p>No scheduled messages to show</p>
        </div>
      ) : (
        messages.map((message, index) => (
          <div className="schedule-row" key={index}>
            <div className="message-info">
              <div className="d-flex">
                <img src={clock} className="message-icon" alt="check icon" />
                <p className="booking-label">{moment(message.scheduleDate).format('MM/DD/YYYY h:mm A')}</p>
              </div>
              <div className="switch-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={messageStatuses[message.id]}
                    onChange={() => handleToggleMessageStatus(message.id)}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </div>
            <div>
              <p className="booking-value mt-8">{message.triggerType}</p>
              <p className="booking-value no font-500">{message.triggerOn} • {message.triggerDay}</p>
            </div>
          </div>
        ))
      )}
    </div>
  );

  const renderHistoryMessages = (messages: Array<any>) => (
    <div className="inbox-content">
      {messages.length === 0 ? (
        <div className="extrainfo-row">
          <p>No history messages to show</p>
        </div>
      ) : (
        messages.map((message, index) => (
          <div className="history-row" key={index}>
            <div className="message-info">
              <div className="d-flex">
                <img src={checkCircle} className="message-icon yes" alt="check icon" />
                <p className="booking-label">{moment(message.scheduleDate).format('MM/DD/YYYY h:mm A')}</p>
              </div>
            </div>
            <div>
              <p className="booking-value mt-8">{message.triggerType}</p>
              <p className="booking-value no font-500">{message.triggerOn} • {message.triggerDay}</p>
            </div>
          </div>
        ))
      )}
    </div>
  );


  const renderInboxContent = () => {
    if (activeInboxTab === 'scheduled') {
      return renderScheduleMessages(scheduledMessages);
    } else {
      return renderHistoryMessages(historyMessages);
    }
  };

  const getDisplayValue = (value: any) => (value === null || value === undefined ? 'N/A' : value);

  const getAmenityImage = (amenityName: string): string | undefined => {
    if (Array.isArray(amenities)) {
      const amenity = amenities.find(a => a.name.toLowerCase() === amenityName.toLowerCase());
      return amenity ? amenity.imageUrl : undefined;
    }
    return undefined;
  };

  const bedIcons: { [key in keyof PropertyBedTypeCounts]: string } = {
    kingBed: kingBedIcon,
    queenBed: queenBedIcon,
    doubleBed: doubleBedIcon,
    bunkBed: bunkBedIcon,
    singleBed: singleBedIcon
  };


  const renderBedsSection = () => {
    if (!reservationData || !reservationData.propertyBedTypeCounts) {
      return null;
    }

    const { propertyBedTypeCounts } = reservationData;

    const bedTypes = (Object.keys(propertyBedTypeCounts) as (keyof PropertyBedTypeCounts)[])
      .filter(bedType => propertyBedTypeCounts[bedType] > 0);

    const totalBeds = bedTypes.reduce((sum, bedType) => sum + (propertyBedTypeCounts[bedType] || 0), 0);

    if (totalBeds === 0) {
      return (
        <div className='extrainfo-container'>
          <div className='extrainfo-row'>
            <h3> Beds </h3>
          </div>
          <div className='extrainfo-row'>
            <p>No beds to show</p>
          </div>
        </div>
      );
    }

    return (
      <div className='extrainfo-container'>
        <div className='extrainfo-row'>
          <h3> Beds </h3>
        </div>
        <div className='beds-container'>
          {bedTypes.map(bedType => (
            <div className='bed-item' key={bedType}>
              <span className='bed-type'>{bedType.replace(/([A-Z])/g, ' $1').trim()}</span>
              <div className="d-flex">
                <span className='bed-count'>{propertyBedTypeCounts[bedType]}</span>
                <img src={bedIcons[bedType]} alt={`${bedType} Icon`} className='bed-icon' />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // const renderBedsSection = () => {
  //   if (!reservationData || !reservationData.propertyBedTypeCounts) {
  //     return null;
  //   }

  //   const { propertyBedTypeCounts } = reservationData;

  //   const bedTypes: (keyof PropertyBedTypeCounts)[] = [
  //     'kingBed',
  //     'queenBed',
  //     'doubleBed',
  //     'bunkBed',
  //     'singleBed'
  //   ];

  //   return (
  //     <div className='extrainfo-container'>
  //       <div className='extrainfo-row'>
  //         <h3> Beds </h3>
  //       </div>
  //       <div className='beds-container'>
  //         {bedTypes.map(bedType => (
  //           <div className='bed-item' key={bedType}>
  //             <span className='bed-type'>{bedType.replace(/([A-Z])/g, ' $1').trim()}</span>
  //             <div className="d-flex">
  //               <span className='bed-count'>{propertyBedTypeCounts[bedType] || 0}</span>
  //               <img src={bedIcons[bedType]} alt={`${bedType} Icon`} className='bed-icon' />
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };



  const renderContent = () => {
    if (loading) {
      return <div className="extrainfo-row">
        <p>Loading...</p>
      </div>;
    }

    if (error) {
      return <div className="extrainfo-row"><p>Error loading reservation details.</p> </div>;
    }

    if (isAdvanceNotice) {
      return (
        <div className='booking-content'>
          <div className='property-row'>
            <div className="booking-label">Property Listing</div>
            <div className="property">
              <div className={propertyPassed?.image ? "listing-image" : "listing-image-none"}>
                <img src={propertyPassed?.image || homeDark} alt={propertyPassed?.name || 'Property'} />
              </div>
              <div>
                <h3>{getDisplayValue(propertyPassed?.name)}</h3>
                <p>{getDisplayValue(propertyPassed?.address)}</p>
              </div>
            </div>
          </div>
          <div className='booking-row-second'>
            <div className='booking-column'>
              <div className="booking-label">Check In</div>
              <div className="booking-value">{moment(getDisplayValue(arrivalDate)).utc().format('DD MMM, YYYY')}</div>
              <div className="booking-label">{moment(getDisplayValue(arrivalDate)).utc().format('h:mm A')}</div>
            </div>
            <div className='booking-column'>
              <div className="booking-label">Check Out</div>
              <div className="booking-value">{moment(getDisplayValue(departureDate)).format('DD MMM, YYYY')}</div>
              <div className="booking-label">{moment(getDisplayValue(departureDate)).format('h:mm A')}</div>
            </div>
          </div>
          <div className='extrainfo-container'>
            <div className='extrainfo-row'>
              <div className=''>
                <div className="booking-label">Block Notes</div>
                <div className="booking-value-p">Blocked due to Advance Notice</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (isAfterBlock) {
      return (
        <div className='booking-content'>
          <div className='property-row'>
            <div className="booking-label">Property Listing</div>
            <div className="property">
              <div className={propertyPassed?.image ? "listing-image" : "listing-image-none"}>
                <img src={propertyPassed?.image || homeDark} alt={propertyPassed?.name || 'Property'} />
              </div>
              <div>
                <h3>{getDisplayValue(propertyPassed?.name)}</h3>
                <p>{getDisplayValue(propertyPassed?.address)}</p>
              </div>
            </div>
          </div>
          <div className='booking-row-second'>
            <div className='booking-column'>
              <div className="booking-label">Check In</div>
              <div className="booking-value">{moment(getDisplayValue(arrivalDate)).format('DD MMM, YYYY')}</div>
              <div className="booking-label">{moment(getDisplayValue(arrivalDate)).format('h:mm A')}</div>
            </div>
            <div className='booking-column'>
              <div className="booking-label">Check Out</div>
              <div className="booking-value">{moment(getDisplayValue(departureDate)).format('DD MMM, YYYY')}</div>
              <div className="booking-label">{moment(getDisplayValue(departureDate)).format('h:mm A')}</div>
            </div>
          </div>
          <div className='extrainfo-container'>
            <div className='extrainfo-row'>
              <div className=''>
                <div className="booking-label">Block Notes</div>
                <div className="booking-value-p">Blocked due to After Block</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (!reservationData) {
      return <div className="extrainfo-row"> <p>No reservation details available.</p> </div>;
    }

    const { reservation, property } = reservationData;

    if (isManualBlock) {
      return (
        <div className='booking-content'>
          <div className='property-row'>
            <div className="booking-label">Property Listing</div>
            <div className="property">
              <div className={propertyPassed?.image ? "listing-image" : "listing-image-none"}>
                <img src={propertyPassed?.image || homeDark} alt={propertyPassed?.name || 'Property'} />
              </div>
              <div>
                <h3>{getDisplayValue(property.response["MARKETING: Listing Title"])}</h3>
                <p>{getDisplayValue(property.response["DETAILS: Property Address - TEXT"])}</p>
              </div>
            </div>
          </div>
          <div className='booking-row-second'>
            <div className='booking-column'>
              <div className="booking-label">Check In</div>
              <div className="booking-value">{moment(getDisplayValue(reservation["BASIC: Check In Date"])).utc().format('DD MMM, YYYY')}</div>
              <div className="booking-label">{moment(getDisplayValue(reservation["BASIC: Check In Date"])).utc().format('h:mm A')}</div>
            </div>
            <div className='booking-column'>
              <div className="booking-label">Check Out</div>
              <div className="booking-value">{moment(getDisplayValue(reservation["BASIC: Check Out Date"])).utc().format('DD MMM, YYYY')}</div>
              <div className="booking-label">{moment(getDisplayValue(reservation["BASIC: Check Out Date"])).utc().format('h:mm A')}</div>
            </div>
          </div>
          <div className='extrainfo-container'>
            <div className='extrainfo-row'>
              <div className=''>
                <div className="booking-label">Block Notes</div>
                <div className="booking-value-p">{getDisplayValue(reservation["INO: Guest Notes"])}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    switch (activeTab) {
      case 'booking':
        return (
          <div className='booking-content'>
            <div className='booking-row'>
              <div className='booking-column'>
                <div className="booking-label">Status</div>
                <div className={`booking-value ${reservation["Status Options"] === 'Confirmed' ? 'booking-confirmed' : 'not-confirmed'}`}>
                  {reservation["Status Options"] === 'Confirmed' ? 'Confirmed' : 'Unconfirmed'}
                </div>

              </div>
              <div className='booking-column'>
                <div className="booking-label">Channel</div>
                <div className="d-flex">
                  <div className="channel-image">
                    <img src={getImageForBookingChannel(getDisplayValue(reservation["Booked Channel"]))} alt="Guest" />
                  </div>
                  <div className="booking-value">{getDisplayValue(reservation["Booked Channel"])}</div>
                </div>

              </div>
            </div>
            <div className='booking-row-second'>
              <div className='booking-column'>
                <div className="booking-label">Guests</div>
                <div className="booking-value">{getDisplayValue(reservation["COUNT: Guest Count"])} Guests</div>
              </div>
            </div>
            <div className='booking-row'>
              <div className='booking-column'>
                <div className="booking-label">Check In</div>
                <div className="booking-value">{moment(getDisplayValue(reservation["BASIC: Check In Date"])).format('DD MMM, YYYY')}</div>
                <div className="d-flex">
                  <img src={clock} alt="Clock" className='small-icon' />
                  <div className="booking-label-no-bottom">{moment(getDisplayValue(reservation["BASIC: Check In Date"])).format('hh:mm A')}</div>
                </div>
              </div>
              <div className='booking-column'>
                <div className="booking-label">Check Out</div>
                <div className="booking-value">{moment(getDisplayValue(reservation["BASIC: Check Out Date"])).format('DD MMM, YYYY')}</div>
                <div className="d-flex">
                  <img src={clock} alt="Clock" className='small-icon' />
                  <div className="booking-label-no-bottom">{moment(getDisplayValue(reservation["BASIC: Check Out Date"])).format('hh:mm A')}</div>
                </div>
              </div>
            </div>
            <div className='booking-row-second'>
              <div className='booking-column'>
                <div className="booking-label">Total Stay</div>
                <div className="booking-value">{getDisplayValue(reservation["BASIC: Stay Length"])} Nights </div>
              </div>
            </div>
            <div className='booking-row'>
              <div className='booking-column'>
                <div className="booking-label">Balance Due</div>
                <div className="booking-value">${parseFloat(getDisplayValue(reservation["BREAKDOWN: Total Fee"])).toFixed(2)}</div>
              </div>
              <div className='booking-column'>
                <div className="booking-label">Paid</div>
                <div className="booking-value paid">${parseFloat(getDisplayValue(reservation["BREAKDOWN: Total Fee"])).toFixed(2)}</div>
              </div>
            </div>
            <div className='booking-row-second'>
              <div className='booking-column'>
                <div className="booking-label">Payout</div>
                <div className="booking-value">$0.00</div>
              </div>
              <div className='booking-column'>
                <div className="booking-label">Sentiment</div>
                <div className="d-flex">
                  <img src={faceHappy} alt="Clock" className='medium-icon' />
                  <div className="booking-value booking-confirmed">Very Satisfied</div>
                </div>
              </div>
            </div>
            <div className='single-row'>
              <div className='booking-column'>
                <div className="booking-label">Transport Method</div>
                <div className="booking-value">N/A</div>
              </div>
              <div className='booking-column'>
                <div className="booking-label">Reason For Visit</div>
                <div className="booking-value">N/A</div>
              </div>
            </div>
            <div className='booking-row'>
              <div className="booking-value-non">Documents Signed? <span className='red'>N/A</span></div>
            </div>
            <div className='single-row'>
              <div className="booking-value-non">Guest CheckIn? <span className='red'>N/A</span></div>
            </div>
            <div className='booking-row'>
              <div className="booking-value-non">Cleaning Status? <span className='red'>{getDisplayValue(property.response.additionalProperties.Cleaned)}</span></div>
            </div>
            <div className='single-row'>
              <div className="booking-value underline">Go to Job Page</div>
            </div>
            <div className='extrainfo-container'>
              <div className='extrainfo-row'>
                <div className='booking-column'>
                  <div className="booking-label">Guest Booklet Code</div>
                  <div className="booking-value">N/A</div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'property':
        return (
          <div>
            <div className='property-row'>
              <div className="booking-label">Property Listing</div>
              <div className="property">
                <div className={propertyPassed?.image ? "listing-image" : "listing-image-none"}>
                  <img src={propertyPassed?.image || homeDark} alt={propertyPassed?.name || 'Property'} />
                </div>
                <div>
                  <h3>{getDisplayValue(property.response["MARKETING: Listing Title"])}</h3>
                  <p>{getDisplayValue(property.response["DETAILS: Property Address - TEXT"])}</p>
                </div>
              </div>
            </div>
            <div className='booking-row'>
              <div className='booking-column'>
                <div className="booking-label">Type</div>
                <div className="booking-value">{getDisplayValue(property.response["DETAILS: Property Type"])}</div>
              </div>
              <div className='booking-column'>
                <div className="booking-label">Guests</div>
                <div className="booking-value">{getDisplayValue(property.response["DETAILS: Guest Count"])}</div>
              </div>
            </div>
            <div className='booking-row-second'>
              <div className='booking-column'>
                <div className="booking-label">Bedrooms</div>
                <div className="booking-value">{getDisplayValue(property.response["DETAILS: Bedroom Count"])}</div>
              </div>
              <div className='booking-column'>
                <div className="booking-label">Bathrooms</div>
                <div className="booking-value">{getDisplayValue(property.response["DETAILS: Bathroom Count"])}</div>
              </div>
            </div>
            <div className='extrainfo-container'>
              <div className='extrainfo-row'>
                <h3> Amenities </h3>
              </div>
              <div className='amenities-row'>
                {property.response["Added Amenities"] && property.response["Added Amenities"].length > 0 ? (
                  property.response["Added Amenities"].map((amenity: string, index: number) => {
                    if (index % 1 === 0) {
                      return (
                        <div className='amenity-column' key={index}>
                          <div className="amenity-info">
                            <div className="amenity-icon">
                              {getAmenityImage(amenity) ? (
                                <img src={getAmenityImage(amenity)} alt={amenity} />
                              ) : (
                                <FontAwesomeIcon icon={faHotel} />
                              )}
                            </div>
                            <div className="booking-label">{getDisplayValue(amenity)}</div>
                          </div>
                          {/* {index + 1 < property.response["Added Amenities"].length && (
                            <div className="profile-info">
                              <div className="amenity-icon">
                                {getAmenityImage(property.response["Added Amenities"][index + 1]) ? (
                                  <img src={getAmenityImage(property.response["Added Amenities"][index + 1])} alt={property.response["Added Amenities"][index + 1]} />
                                ) : (
                                  <FontAwesomeIcon icon={faHotel} />
                                )}
                              </div>
                              <div className="booking-label">{getDisplayValue(property.response["Added Amenities"][index + 1])}</div>
                            </div>
                          )} */}
                        </div>
                      );
                    }
                    return null;
                  })
                ) : (
                  <div className='extrainfo-row'>
                    <p>No amenities to show</p>
                  </div>
                )}
              </div>
            </div>



            {renderBedsSection()}

            <div className='extrainfo-container'>
              <div className='extrainfo-row'>
                <h3> Basic Info</h3>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Custom Check Out for AUTOMATED MESSAGES</div>
                  <div className="booking-value">N/A</div>
                </div>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Booklet Link</div>
                  <div className="booking-value underline booking-confirmed">
                    <div className="booking-value underline booking-confirmed"> <a href={getDisplayValue(reservation["guest-booklet-link"]) || "#"} target="_blank" rel="noopener noreferrer">Click Here</a></div>
                  </div>
                </div>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Guest Lockbox Code</div>
                  <div className="booking-value">{getDisplayValue(property.response["ACCESS: Guest Lockbox Code"])}</div>
                </div>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Emergency Lockbox Code</div>
                  <div className="booking-value">{getDisplayValue(property.response["ACCESS: Cleaner Lockbox Code"])}</div>
                </div>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Wifi Username</div>
                  <div className="booking-value ">{getDisplayValue(property.response["WIFI: Username"])}</div>
                </div>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Wifi Password</div>
                  <div className="booking-value">{getDisplayValue(property.response["WIFI: Password "])}</div>
                </div>
              </div>
              {/* <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Property ID</div>
                  <div className="booking-value">{getDisplayValue(property.response["DETAILS: Property Address - TEXT"])}</div>
                </div>
              </div> */}
            </div>
            <div className='extrainfo-row'>
              <div className=''>
                <div className="booking-label">Cleaner</div>
                <div className="profile-info">
                  <div className="profile-img cleaner">{getInitials("Ram Raut")}</div>
                  <div className="booking-value">Ram Raut</div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'guest':
        return (
          <div>
            <div className='extrainfo-container'>
              <div className='extrainfo-row'>
                <h3> Guest Info </h3>
              </div>
            </div>
            <div className='extrainfo-container'>
              <div className="guest-info-header">
                <div className="guest-initials">{getInitials(getDisplayValue(reservation["GUEST: Guest Name"]))}</div>
                <div className="guest-name">{getDisplayValue(reservation["GUEST: Guest Name"])}</div>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Email</div>
                  <div className="booking-value">{getDisplayValue(reservation["GUEST: Email"])}</div>
                </div>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Phone</div>
                  <div className="booking-value">{getDisplayValue(reservation["GUEST: Mobile"])}</div>
                </div>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Address</div>
                  <div className="booking-value">
                    {reservation["GUEST: Guest Personal Location"] ? getDisplayValue(reservation["GUEST: Guest Personal Location"].address) : 'N/A'}
                  </div>

                </div>
              </div>
            </div>
            <div className='extrainfo-container'>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">Notes</div>
                  <div className="booking-value-p">{getDisplayValue(reservation["INO: Guest Notes"])}</div>
                </div>
              </div>
            </div>
            <div className='extrainfo-container'>
              <div className='extrainfo-row'>
                <h3> External Profile </h3>
              </div>
              <div className='extrainfo-row'>
                <div className=''>
                  <div className="booking-label">OTA Link</div>
                  <div className="profile-info">
                    <div className="ota-img">
                      <img src={getImageForBookingChannel(getDisplayValue(reservation["Booked Channel"]))} alt="Guest" />
                    </div>
                    <div className="booking-value">{getDisplayValue(reservation["Booked Channel"])}</div>
                  </div>


                </div>
              </div>
            </div>
            <div className='extrainfo-container'>
              <div className='extrainfo-row'>
                <div className='booking-column'>
                  <div className="booking-label">Marketing Consent?</div>
                  {/* <div className="booking-value no">{getDisplayValue(reservation["Marketing Consent"])}</div> */}
                  <div className="booking-value no">N/A</div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'auto-message':
        return (
          <div className='auto-message-content'>
            <div className='extrainfo-container'>
              <div className='extrainfo-row'>
                <h3> Guest Auto Messages</h3>
              </div>
              <div className='auto-message-tabs'>
                <button
                  className={activeInboxTab === 'scheduled' ? 'active' : ''}
                  onClick={() => setActiveInboxTab('scheduled')}
                >
                  Scheduled
                </button>
                <button
                  className={activeInboxTab === 'history' ? 'active' : ''}
                  onClick={() => setActiveInboxTab('history')}
                >
                  History
                </button>
              </div>
            </div>
            {renderInboxContent()}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* <div className="close-button-wrapper">
        <button className="back-button" onClick={onClose}>
          <img src={backIcon} alt="Back Icon" />
        </button>
      </div> */}
      <div className={`sidebar ${sideBar ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>
            {isManualBlock
              ? "Manual Block"
              : isAdvanceNotice
                ? "Advance Notice"
                : isAfterBlock
                  ? "After Block"
                  : guestName}
          </h2>
          <div className="sidebar-header-icons">
            <button className="out-button" onClick={() => handleRedirect(getDisplayValue(reservationData?.reservation._id))}
            >
              <img src={linkIcon} alt="link icon" />
            </button>
            <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className='x-icon' /></button>
          </div>
        </div>


        <div className="sidebar-body">
          <div className="sidebar-content">
            {renderContent()}
          </div>

          <div className="sidebar-tabs">
            <button onClick={() => setActiveTab('booking')} className={activeTab === 'booking' ? 'active' : ''}>
              <img src={activeTab === 'booking' ? calendarDark : calendarLight} alt="Calendar Icon" />
            </button>
            {!isManualBlock && !isAdvanceNotice && !isAfterBlock && (
              <>
                <button onClick={() => setActiveTab('property')} className={activeTab === 'property' ? 'active' : ''}>
                  <img src={activeTab === 'property' ? homeDark : homeLight} alt="Home Icon" />
                </button>
                <button onClick={() => setActiveTab('guest')} className={activeTab === 'guest' ? 'active' : ''}>
                  <img src={activeTab === 'guest' ? userDark : userLight} alt="User Icon" />
                </button>
                <button onClick={() => setActiveTab('auto-message')} className={activeTab === 'auto-message' ? 'active' : ''}>
                  <img src={activeTab === 'auto-message' ? inboxDark : inboxLight} alt="Inbox Icon" />
                </button>
              </>
            )}

          </div>

        </div>
      </div>
    </>
  );
};

export default BookingSidebar;
