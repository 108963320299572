import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import Listing from './Listing';
import Calendar from './Calendar';
import FindAvailabilityModal from './modals/FindAvailabilityModal';
import { generateDatesForMonth } from '../utils/dateUtils';
import moment from 'moment';
import { PropertyList, PropertyListResponse, CalendarResponse, Listing as ListingType, CalendarPMS } from '../api/type';
import { fetchProperties, fetchCalendarData, fetchPropertiesAvailability } from '../api/request';
import leftIcon from './assets/left.png';
import rightIcon from './assets/right.png';
import { ImportantOrgContext } from '../App';
import BookingSidebar from './sidebars/BookingSidebar';
import ReservationSidebar from './sidebars/ReservationSidebar';
import JobSidebar from './sidebars/JobSidebar';
import JobDetailSidebar from './sidebars/JobDetailSidebar';
import MoreJobDetailSidebar from './sidebars/MoreJobDetailSidebar';
import { faL } from '@fortawesome/free-solid-svg-icons';

interface Job {
  id: string;
  jobType: 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';
  status: string;
  date: string;
  startDate: string;
  endDate: string;
  duration: string;
}

interface CalendarRef {
  scrollToCurrentDate: () => void;
  scrollToFirstDate: () => void;
}

const CalendarApp = () => {
  const importantOrgId = useContext(ImportantOrgContext);

  const [properties, setProperties] = useState<PropertyList[]>([]);
  const [filteredProperties, setFilteredProperties] = useState<PropertyList[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dates, setDates] = useState<string[]>([]);
  const [currentMonth, setCurrentMonth] = useState(moment().format("MMMM YYYY"));
  const [propertyCount, setPropertyCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calendarData, setCalendarData] = useState<ListingType[]>([]);
  const [loading, setLoading] = useState(true);
  const [cache, setCache] = useState<{ [key: string]: ListingType[] }>({});
  const [selectedDate, setSelectedDate] = useState<string>(moment().format("MMMM YYYY"));
  // State for Booking Sidebar
  const [isBookingSidebarOpen, setIsBookingSidebarOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<CalendarPMS | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<PropertyList | undefined>(undefined);

  const [isJobSidebarOpen, setIsJobSidebarOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);

  const [isReservationSidebarOpen, setIsReservationSidebarOpen] = useState(false);

  const [isJobDetailSidebarOpen, setIsJobDetailSidebarOpen] = useState(false);

  const openBookingSidebar = (booking: CalendarPMS) => {
    closeAllSidebars();
    setSelectedBooking(booking);
    setIsBookingSidebarOpen(true);
  };

  const closeBookingSidebar = () => {
    setSelectedBooking(null);
    setIsBookingSidebarOpen(false);
  };

  const openJobSidebar = (property: PropertyList | undefined, date: string) => {
    closeAllSidebars();
    setSelectedDate(date);
    setSelectedProperty(property);
    setIsJobSidebarOpen(true);
  };

  const closeJobSidebar = () => {
    setIsJobSidebarOpen(false);
    setSelectedDate(moment().format("MMMM YYYY"));
    setSelectedProperty(undefined);
  };

  const openReservationSidebar = (property: PropertyList | undefined, date: string) => {
    closeAllSidebars();
    setSelectedDate(date);
    setSelectedProperty(property);
    setIsReservationSidebarOpen(true);
  };

  const closeReservationSidebar = () => {
    setIsReservationSidebarOpen(false);
    setSelectedDate(moment().format("MMMM YYYY"));
    setSelectedProperty(undefined);
  };

  const openJobDetailSidebar = (job: Job, property: PropertyList | undefined) => {
    closeAllSidebars();
    setSelectedJob(job);
    setSelectedProperty(property);
    setIsJobDetailSidebarOpen(true);
  };

  const closeJobDetailSidebar = () => {
    setIsJobDetailSidebarOpen(false);
    setSelectedJob(null);
    setSelectedProperty(undefined);
  };

  const closeAllSidebars = () => {
    if (isJobSidebarOpen) {
      closeJobSidebar();
    }
    if (isReservationSidebarOpen) {
      closeReservationSidebar();
    }
    if (isBookingSidebarOpen) {
      closeBookingSidebar();
    }
    if (isJobDetailSidebarOpen) {
      closeJobDetailSidebar();
    }
  };

  const calendarRef = useRef<CalendarRef>(null);

  const fetchCalendarDataForMonth = async (month: string, year: string) => {
    const monthKey = `${month}-${year}`;

    // Check if data is in the cache
    if (cache[monthKey]) {
      setCalendarData(cache[monthKey]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const propertiesResponse: PropertyListResponse = await fetchProperties(importantOrgId);
      if (propertiesResponse.data) {
        setProperties(propertiesResponse.data);
        const propertyIdList = propertiesResponse.data.map(property => property.id);
        const startDate = moment(`${year}-${month}-01`).startOf('month').format('YYYY-MM-DD');
        const endDate = moment(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');
        const calendarResponse: CalendarResponse = await fetchCalendarData(
          startDate,
          endDate,
          propertyIdList
        );

        if (calendarResponse.data) {
          const mappedData = propertiesResponse.data.map(property => {
            const calendarItem = calendarResponse.data?.find(cd => cd.calendarPropertyId === property.id);
            return {
              calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
              calendarPropertyId: property.id,
              propertyAddress: property.address,
              propertyTitle: property.name,
              jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
            };
          });

          // Save the data in cache
          setCache(prevCache => ({
            ...prevCache,
            [monthKey]: mappedData as ListingType[]
          }));

          setCalendarData(mappedData as ListingType[]);
          setFilteredProperties(propertiesResponse.data);
          setPropertyCount(propertiesResponse.data.length);

          if (moment().format('MMMM YYYY') === `${month} ${year}`) {
            scrollToToday();
          } else {
            if (calendarRef.current) {
              calendarRef.current.scrollToFirstDate();
            }
          }
        } else {
          setCalendarData([]);
          setFilteredProperties([]);
          setPropertyCount(0);
        }
      } else {
        setProperties([]);
        setCalendarData([]);
        setFilteredProperties([]);
        setPropertyCount(0);
      }
    } catch (error) {
      console.error('Error loading data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentYear = moment().year();
    const currentMonthIndex = moment().month();
    const monthName = moment().format('MMMM');
    const year = moment().format('YYYY');
    setDates(generateDatesForMonth(currentYear, currentMonthIndex));
    fetchCalendarDataForMonth(monthName, year);
  }, []);

  useEffect(() => {
    const results = properties.filter(property =>
      property.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProperties(results);
    setPropertyCount(results.length);
  }, [searchTerm, properties]);

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleMonthChange = useCallback((direction: 'prev' | 'next') => {
    const newMonth = moment(currentMonth, "MMMM YYYY").add(direction === 'next' ? 1 : -1, 'month').format("MMMM YYYY");
    const [monthName, year] = newMonth.split(" ");
    const monthIndex = moment().month(monthName).month();
    setDates(generateDatesForMonth(Number(year), monthIndex));
    setCurrentMonth(newMonth);

    if (moment().format('MMMM YYYY') !== newMonth) {
      if (calendarRef.current) {
        calendarRef.current.scrollToFirstDate();
      }
    }

    fetchCalendarDataForMonth(monthName, year);
  }, [currentMonth]);

  const scrollToToday = useCallback(() => {
    if (calendarRef.current) {
      calendarRef.current.scrollToCurrentDate();
    }
  }, []);

  const handleTodayClick = useCallback(() => {
    const todayMonth = moment().format("MMMM YYYY");
    if (currentMonth !== todayMonth) {
      if (calendarRef.current) {
        calendarRef.current.scrollToFirstDate();
      }
      const currentYear = moment().year();
      const currentMonthIndex = moment().month();
      const monthName = moment().format('MMMM');
      const year = moment().format('YYYY');
      setDates(generateDatesForMonth(currentYear, currentMonthIndex));
      setCurrentMonth(todayMonth);
      fetchCalendarDataForMonth(monthName, year);
    } else if (calendarRef.current) {
      calendarRef.current.scrollToCurrentDate();
    }
  }, [currentMonth, fetchCalendarDataForMonth]);

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const updateProperties = async (checkInDate: string, checkOutDate: string, city: string) => {
    try {
      setLoading(true);
      const propertiesResponse = await fetchPropertiesAvailability(importantOrgId, checkInDate, checkOutDate, city);
      if (propertiesResponse.data) {
        setProperties(propertiesResponse.data);

        const propertyIdList = propertiesResponse.data.map(property => property.id);
        const calendarResponse = await fetchCalendarData(checkInDate, checkOutDate, propertyIdList);

        const mappedData = propertiesResponse.data.map(property => {
          const calendarItem = calendarResponse.data?.find(cd => cd.calendarPropertyId === property.id);
          return {
            calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
            calendarPropertyId: property.id,
            propertyAddress: property.address,
            propertyTitle: property.name,
            jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
          };
        });

        setCalendarData(mappedData as ListingType[]);
        setFilteredProperties(propertiesResponse.data);
        setPropertyCount(propertiesResponse.data.length);
      } else {
        setProperties([]);
        setCalendarData([]);
        setFilteredProperties([]);
        setPropertyCount(0);
      }
    } catch (error) {
      console.error('Error updating properties', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="multicalendar-header-container">
        <div className="search-listings">
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Search Listings"
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>
        </div>
        <div className="calendar-header">
          <div className="date-filter-container">
            <div className='date-filter'>
              <button className="navi-btn" onClick={() => handleMonthChange('prev')}><img src={leftIcon} alt="left-button" /></button>
              <span className="mx-3">{currentMonth}</span>
              <button className="navi-btn" onClick={() => handleMonthChange('next')}><img src={rightIcon} alt="right-button" /></button>
            </div>
            <div>
              <button className="secondary-btn" onClick={handleTodayClick}>Today</button>
            </div>
          </div>
          <div className='another-button'>
            <button className='primary-btn' onClick={toggleModal}>Availability</button>
          </div>
        </div>
      </div>
      <div className="multicalendar-container">
        <div className="listings">
          <div className='listings-header'>
            {propertyCount} Properties {searchTerm ? 'Found' : 'Active'}
          </div>
          {filteredProperties.map(property => (
            <Listing key={property.id} listing={property} />
          ))}
        </div>
        <Calendar
          ref={calendarRef}
          listings={calendarData.filter(cd => filteredProperties.some(fp => fp.id === cd.calendarPropertyId))}
          dates={dates}
          loading={loading}
          properties={properties}
          onBookingClick={openBookingSidebar}
          onJobClick={openJobSidebar}
          onReservationClick={openReservationSidebar}
          onJobDetailClick={openJobDetailSidebar}
        />

      </div>
      {isModalOpen && <FindAvailabilityModal onClose={toggleModal} updateProperties={updateProperties} />}
      {/* Booking Sidebar */}
      {isBookingSidebarOpen && (
        <BookingSidebar
          reservationId={selectedBooking?.reservationPMS?.id}
          guestName={selectedBooking?.reservationPMS?.guestFullName}
          isManualBlock={selectedBooking?.reservationPMS?.otaName === 'Manual Block'}
          isAdvanceNotice={selectedBooking?.reservationPMS?.otaName === 'Advance Notice'}
          isAfterBlock={selectedBooking?.reservationPMS?.otaName === 'After Block'}
          arrivalDate={selectedBooking?.reservationPMS?.arrivalDate}
          departureDate={selectedBooking?.reservationPMS?.departureDate}
          onClose={closeBookingSidebar}
          sideBar={isBookingSidebarOpen}
          propertyPassed={selectedProperty}
        />
      )}

      {
        isJobSidebarOpen && (
          <JobSidebar
            selectedDate={selectedDate}
            onClose={closeJobSidebar}
            sideBar={isJobSidebarOpen}
            property={selectedProperty}
          />
        )
      }
      {
        isReservationSidebarOpen && (
          <ReservationSidebar
            onClose={closeReservationSidebar}
            sideBar={isReservationSidebarOpen}
            selectedDate={selectedDate}
            property={selectedProperty}
          />
        )
      }

      {
        isJobDetailSidebarOpen && selectedJob && (
          <JobDetailSidebar
            onClose={closeJobDetailSidebar}
            sideBar={isJobDetailSidebarOpen}
            jobDetail={selectedJob}
            property={selectedProperty}
          />
        )
      }
    </>
  );
};

export default React.memo(CalendarApp);





