import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete';

interface GooglePlacesAutocompleteProps {
  setCity: (city: string) => void;
  clearTrigger: boolean; // New prop to trigger clearing
}

const GooglePlacesAutocomplete: React.FC<GooglePlacesAutocompleteProps> = ({ setCity, clearTrigger }) => {
  const [address, setAddress] = useState<string>('');

  useEffect(() => {
    if (clearTrigger) {
      setAddress(''); // Clear the input when clearTrigger changes
    }
  }, [clearTrigger]);

  const handleChange = (address: string) => {
    setAddress(address);
  };

  const handleSelect = (address: string) => {
    setAddress(address); // Update the input field with the selected address
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log('Success', latLng);
        setCity(address);
      })
      .catch(error => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: 'relative' }}>
          <input
            {...getInputProps({
              placeholder: 'Search City...',
              className: 'location-search-input',
            })}
          />
          <div className="autocomplete-dropdown-container" style={{ position: 'absolute', top: '100%', zIndex: 1 }}>
            {loading && <div className="suggestion-item">Loading...</div>}
            {suggestions.map((suggestion: Suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAutocomplete;
