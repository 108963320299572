import * as methods from './methods';
import {
  PropertyListResponse, CalendarResponse, ReservationDetailsResponse, CreateManualBlockRequest, CreateManualBlockResponse, CreateNewReservationRequest,
  CreateNewReservationResponse, CreateOwnerBlockRequest, CreateOwnerBlockResponse, UpdateAutomationStatusResponse, CreateJobRequest, CreateJobResponse, ContractorsResponse, OwnerResponse,
  ImagesResponse, AccommodationFeeRequest, AccommodationFeeResponse
} from './type';

// Request for fetching properties
export const fetchProperties = async (importantOrgId: string): Promise<PropertyListResponse> => {
  try {
    const response = await methods.get('api/v1/multi-calendar/properties', {
      importantOrgId: importantOrgId,
    });

    console.log("this is from property api", response.data);
    return response.data;

  } catch (error) {
    console.error('Error fetching properties:', error);
    throw error;
  }
};

export const authenticateimportantOrgId = async (token: string, userId: string, importantOrgId: string): Promise<boolean> => {
  try {
    const response = await methods.post('api/v1/multi-calendar/authenticate', {
      token: token,
      userId: userId,
      importantOrgId: importantOrgId,
    });

    console.log("Authentication api response data: ", response.data);
    return response.data;

  } catch (error) {
    console.error('Error in Authenticating API: ', error);
    throw error;
  }
};

// Request for fetching associated contractors
export const fetchAssociatedContractors = async (importantOrgId: string, propertyId: string): Promise<ContractorsResponse> => {
  try {
    const response = await methods.get('api/v1/multi-calendar/associated-contractors', {
      importantOrgId,
      propertyId
    });

    console.log("Associated contractors data:", response.data);
    return response.data;

  } catch (error) {
    console.error('Error fetching associated contractors:', error);
    throw error;
  }
};

export const fetchPropertiesAvailability = async (importantOrgId: string, startDate: string, endDate: string, city: string): Promise<PropertyListResponse> => {
  try {
    const response = await methods.get('api/v1/multi-calendar/properties', {
      importantOrgId: importantOrgId,
      startDate,
      endDate,
      city
    });

    console.log("this is from property api", response.data);
    return response.data || { data: [] };

  } catch (error) {
    console.error('Error fetching properties:', error);
    throw error;
  }
};

// Request for calendar data
export const fetchCalendarData = async (startDate: string, endDate: string, propertyIdList: string[]): Promise<CalendarResponse> => {
  console.log("start date and end date", startDate + endDate);
  console.log("propertyIdList", propertyIdList);

  try {
    const response = await methods.post('api/v3/bulk/getCalendarDateBYHotelIdAndDate', {
      startDate,
      endDate,
      propertyIdList
    });

    console.log("calendar data", response.data);
    return response.data;

  } catch (error) {
    console.error('Error fetching calendar data:', error);
    throw error;
  }
};

// Fetch reservation details
export const fetchReservationDetails = async (reservationId: string): Promise<ReservationDetailsResponse> => {
  try {
    const response = await methods.get(`api/v1/multi-calendar/reservation/${reservationId}/details`);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error fetching reservation details:', error);
    throw error;
  }
};

// Toggle active status
export const updateAutomationStatus = async (
  automationItemId: string,
  isActive: boolean
): Promise<UpdateAutomationStatusResponse> => {
  try {
    const response = await methods.patch<{ isActive: boolean }>(
      `api/v1/automation/${automationItemId}/updateActiveStatus`,
      { isActive }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error updating automation status:', error);
    throw error;
  }
};

// Reservation:: manual, owner and new reservation

// New reservation
export const createNewReservation = async (
  requestData: CreateNewReservationRequest
): Promise<CreateNewReservationResponse> => {
  try {
    const response = await methods.post<CreateNewReservationRequest, undefined>(
      'api/v1/reservation/createNewReservation',
      requestData
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error creating new reservation:', error);
    throw error;
  }
};

// Manual block
export const createManualBlock = async (
  requestData: CreateManualBlockRequest
): Promise<CreateManualBlockResponse> => {
  try {
    const response = await methods.post<CreateManualBlockRequest, undefined>(
      'api/v1/reservation/createManualBlock',
      requestData
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error creating manual block:', error);
    throw error;
  }
};

// Owner block
export const createOwnerBlock = async (
  requestData: CreateOwnerBlockRequest
): Promise<CreateOwnerBlockResponse> => {
  try {
    const response = await methods.post<CreateOwnerBlockRequest, undefined>(
      'api/v1/reservation/createOwnerBlock',
      requestData,
      undefined
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error creating owner block:', error);
    throw error;
  }
};

// Create job
export const createJob = async (
  requestData: CreateJobRequest
): Promise<CreateJobResponse> => {
  try {
    console.log("request data for job", requestData);
    const response = await methods.post<CreateJobRequest, undefined>(
      'api/v1/job/create',
      requestData
    );
    console.log("create job", response);
    return response.data;
  } catch (error) {
    console.error('Error creating job:', error);
    throw error;
  }
};



// Request for fetching property owner
export const fetchPropertyOwner = async (propertyId: string): Promise<OwnerResponse> => {
  try {
    const response = await methods.get(`api/v1/multi-calendar/property/${propertyId}/owner`);
    console.log("Property owner data:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching property owner:', error);
    throw error;
  }
};

// Fetch images for a specific item ID
export const fetchImages = async (itemsId: string): Promise<ImagesResponse> => {
  try {
    const response = await methods.get(`api/v1/multi-calendar/items/${itemsId}/images`);
    console.log("Images data:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching images:', error);
    throw error;
  }
};


export const fetchAccommodationFee = async (
  checkInDate: string,
  checkOutDate: string,
  propertyId: string
): Promise<AccommodationFeeResponse> => {
  try {
    const response = await methods.post<{
      checkInDate: string;
      checkOutDate: string;
      propertyId: string;
    }, undefined>(
      'api/v1/reservation/getAccomodationFeeByReservation',
      {
        checkInDate,
        checkOutDate,
        propertyId
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching accommodation fee:', error);
    throw error;
  }
};


export const fetchAvailability = async (propertyId: string, startDate: string, endDate: string): Promise<{ reservationIsAvailable: boolean, alreadyExistingReservations: any[] }> => {
  try {
    const response = await methods.get(`api/v1/multi-calendar/property/${propertyId}/check-availability`, {
      startDate,
      endDate,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching availability:', error);
    throw error;
  }
};