// import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import GuestSelector from '../dropdown/GuestSelector';
// import GooglePlacesAutocomplete from '../dropdown/GooglePlacesAutocomplete';
// import { geocodeByAddress } from 'react-places-autocomplete';
// import xIcon from '../assets/x.png';


// interface FindAvailabilityModalProps {
//   onClose: () => void;
//   updateProperties: (checkInDate: string, checkOutDate: string, city: string) => void;
// }

// const FindAvailabilityModal: React.FC<FindAvailabilityModalProps> = ({ onClose, updateProperties }) => {
//   const [checkInDate, setCheckInDate] = useState<string>('');
//   const [checkOutDate, setCheckOutDate] = useState<string>('');
//   const [adults, setAdults] = useState<string>('1');
//   const [children, setChildren] = useState<string>('0');
//   const [infants, setInfants] = useState<string>('0');
//   const [city, setCity] = useState<string>('');
//   const [errors, setErrors] = useState<{ [key: string]: string }>({});
//   const [loading, setLoading] = useState<boolean>(false);
//   const [formError, setFormError] = useState<string>('');

//   const validateForm = () => {
//     const newErrors: { [key: string]: string } = {};

//     if (!checkInDate) newErrors.checkInDate = 'Check In Date is required.';
//     if (!checkOutDate) newErrors.checkOutDate = 'Check Out Date is required.';
//     if (checkInDate && checkOutDate) {
//       const checkIn = new Date(checkInDate);
//       const checkOut = new Date(checkOutDate);

//       if (checkIn >= checkOut) newErrors.checkInDate = 'Check In Date must be before Check Out Date.';
//       if ((checkOut.getTime() - checkIn.getTime()) < 24 * 60 * 60 * 1000) {
//         newErrors.checkOutDate = 'Check Out Date must be at least one day after Check In Date.';
//       }
//     }

//     if (!city) newErrors.city = 'City is required.';

//     setErrors(newErrors);

//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (event: React.FormEvent) => {
//     event.preventDefault();
//     setFormError('');

//     if (validateForm()) {
//       setLoading(true);
//       try {
//         const availabilityDetails = {
//           checkInDate,
//           checkOutDate,
//           guests: { adults, children, infants },
//           city
//         };
//         console.log('Availability Details:', availabilityDetails);

//         // Use the passed updateProperties function to update the state in the parent component
//         await updateProperties(checkInDate, checkOutDate, city);

//         onClose();  // Close the modal on successful search
//       } catch (error) {
//         console.error('Error submitting the form', error);
//         setFormError('Failed to find availability. Please try again.');
//       } finally {
//         setLoading(false);
//       }
//     } else {
//       setFormError('Please correct the errors in the form.');
//     }
//   };

//   const handleClear = () => {
//     setCheckInDate('');
//     setCheckOutDate('');
//     setAdults('1');
//     setChildren('0');
//     setInfants('0');
//     setCity('');
//     setErrors({});
//     setFormError('');
//   };

//   const handlePlaceSelect = async (selectedCity: string) => {
//     try {
//       const result = await geocodeByAddress(selectedCity);
//       console.log('Geocoding result:', result);
//       setCity(result[0].formatted_address);
//     } catch (error) {
//       console.error('Error with geocoding:', error);
//       setFormError('Failed to get geocode. Please try again.');
//     }
//   };

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <div className="modal-head">
//           <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className='x-icon' /></button>
//         </div>
//         <div className="modal-header">
//           <h2>Find Availability</h2>
//         </div>
//         <div className="modal-body">
//           <form onSubmit={handleSubmit}>
//             <div className="form-row">
//               <div className="form-group">
//                 <label htmlFor="checkInDate">Check In Date</label>
//                 <input
//                   type="date"
//                   id="checkInDate"
//                   name="checkInDate"
//                   value={checkInDate}
//                   onChange={(e) => setCheckInDate(e.target.value)}
//                 />
//                 {errors.checkInDate && <div className="error-message">{errors.checkInDate}</div>}
//               </div>
//               <div className="form-group">
//                 <label htmlFor="checkOutDate">Check Out Date</label>
//                 <input
//                   type="date"
//                   id="checkOutDate"
//                   name="checkOutDate"
//                   value={checkOutDate}
//                   onChange={(e) => setCheckOutDate(e.target.value)}
//                 />
//                 {errors.checkOutDate && <div className="error-message">{errors.checkOutDate}</div>}
//               </div>
//             </div>
//             <div className="form-group">
//               <label htmlFor="guests">Guest Count</label>
//               <input
//                 type="text"
//                 id="guestCount"
//                 name="selectedGuest"
//                 value=""
//                 placeholder="Enter Guest Count"
//               />
//               {errors.guests && <div className="error-message">{errors.guests}</div>}
//             </div>
//             <div className="form-group">
//               <label htmlFor="city">City</label>
//               <GooglePlacesAutocomplete setCity={handlePlaceSelect} />
//               {errors.city && <div className="error-message">{errors.city}</div>}
//             </div>
//             {formError && (
//               <div className="error-message">
//                 <FontAwesomeIcon icon={faTimesCircle} /> {formError}
//               </div>
//             )}
//             <div className="modal-footer">

//               <div className="form-actions">
//                 <button
//                   type="button"
//                   className="secondary-btn font-500 mx-1"
//                   onClick={handleClear}
//                 >
//                   Clear
//                 </button>
//                 <button
//                   type="submit"
//                   className="primary-btn font-500"
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <>
//                       <FontAwesomeIcon icon={faSpinner} spin /> Processing...
//                     </>
//                   ) : (
//                     'Find Availability'
//                   )}
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FindAvailabilityModal;


import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import GooglePlacesAutocomplete from '../dropdown/GooglePlacesAutocomplete';
import xIcon from '../assets/x.png';

interface FindAvailabilityModalProps {
  onClose: () => void;
  updateProperties: (checkInDate: string, checkOutDate: string, city: string) => void;
}

const FindAvailabilityModal: React.FC<FindAvailabilityModalProps> = ({ onClose, updateProperties }) => {
  const [checkInDate, setCheckInDate] = useState<string>('');
  const [checkOutDate, setCheckOutDate] = useState<string>('');
  const [guestCount, setGuestCount] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [clearTrigger, setClearTrigger] = useState<boolean>(false); // Control the clearing of the GooglePlacesAutocomplete input
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!checkInDate) newErrors.checkInDate = 'Check In Date is required.';
    if (!checkOutDate) newErrors.checkOutDate = 'Check Out Date is required.';
    if (checkInDate && checkOutDate) {
      const checkIn = new Date(checkInDate);
      const checkOut = new Date(checkOutDate);

      if (checkIn >= checkOut) newErrors.checkInDate = 'Check In Date must be before Check Out Date.';
      if ((checkOut.getTime() - checkIn.getTime()) < 24 * 60 * 60 * 1000) {
        newErrors.checkOutDate = 'Check Out Date must be at least one day after Check In Date.';
      }
    }

    if (!city) newErrors.city = 'City is required.';
    if (!guestCount) newErrors.guestCount = 'Guest count is required.'; // Validate guest count

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormError('');

    if (validateForm()) {
      setLoading(true);
      try {
        const availabilityDetails = {
          checkInDate,
          checkOutDate,
          guests: guestCount,
          city,
        };
        console.log('Availability Details:', availabilityDetails);

        // Use the passed updateProperties function to update the state in the parent component
        await updateProperties(checkInDate, checkOutDate, city);

        onClose(); // Close the modal on successful search
      } catch (error) {
        console.error('Error submitting the form', error);
        setFormError('Failed to find availability. Please try again.');
      } finally {
        setLoading(false);
      }
    } else {
      setFormError('Please correct the errors in the form.');
    }
  };

  const handleClear = () => {
    setCheckInDate('');
    setCheckOutDate('');
    setGuestCount(''); // Clear guest count
    setCity(''); // Clear city input
    setClearTrigger((prev) => !prev); // Toggle the clearTrigger to reset GooglePlacesAutocomplete
    setErrors({});
    setFormError('');
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-head">
          <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className="x-icon" /></button>
        </div>
        <div className="modal-header">
          <h2>Find Availability</h2>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="checkInDate">Check In Date</label>
                <input
                  type="date"
                  id="checkInDate"
                  name="checkInDate"
                  value={checkInDate}
                  onChange={(e) => setCheckInDate(e.target.value)}
                />
                {errors.checkInDate && <div className="error-message">{errors.checkInDate}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="checkOutDate">Check Out Date</label>
                <input
                  type="date"
                  id="checkOutDate"
                  name="checkOutDate"
                  value={checkOutDate}
                  onChange={(e) => setCheckOutDate(e.target.value)}
                />
                {errors.checkOutDate && <div className="error-message">{errors.checkOutDate}</div>}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="guestCount">Guest Count</label>
              <input
                type="number"
                id="guestCount"
                name="guestCount"
                value={guestCount}
                placeholder="Enter Guest Count"
                onChange={(e) => setGuestCount(e.target.value)} // Ensure the guest count updates correctly
              />
              {errors.guestCount && <div className="error-message">{errors.guestCount}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="city">City</label>
              <GooglePlacesAutocomplete
                setCity={setCity}
                clearTrigger={clearTrigger} // Pass clearTrigger to reset input
              />
              {errors.city && <div className="error-message">{errors.city}</div>}
            </div>
            {formError && (
              <div className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} /> {formError}
              </div>
            )}
            <div className="modal-footer">
              <div className="form-actions">
                <button
                  type="button"
                  className="secondary-btn font-500 mx-1"
                  onClick={handleClear}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="primary-btn font-500"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <FontAwesomeIcon icon={faSpinner} spin /> Processing...
                    </>
                  ) : (
                    'Find Availability'
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FindAvailabilityModal;

