import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import DayCell from './DayCell';
import { CalendarPMS, PropertyList } from '../api/type';

interface ListingType {
  calendarPMSList: CalendarPMS[];
  calendarPropertyId: string;
  propertyAddress: string | null;
  propertyTitle: string | null;
}

interface CalendarProps {
  listings: ListingType[];
  dates: string[];
  loading: boolean;
  properties: PropertyList[];
  onBookingClick: any;
  onJobClick: any;
  onReservationClick: any;
  onJobDetailClick: any;
}

const Calendar = forwardRef(({ listings, dates, loading, properties, onBookingClick, onJobClick, onReservationClick, onJobDetailClick }: CalendarProps, ref) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    scrollToCurrentDate: () => {
      const today = moment().format('YYYY-MM-DD');
      const currentDateIndex = dates.findIndex(date => moment(date).format('YYYY-MM-DD') === today);
      if (calendarContainerRef.current && currentDateIndex !== -1) {
        const dateElement = calendarContainerRef.current.querySelector(`.date-header-cell-${currentDateIndex}`);
        if (dateElement) {
          dateElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    scrollToFirstDate: () => {
      if (calendarContainerRef.current) {
        const dateElement = calendarContainerRef.current.querySelector(`.date-header-cell-0`);
        if (dateElement) {
          dateElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }));

  return (
    <div className="calendar" ref={calendarContainerRef}>
      <div className="calendar-date-header">
        {dates.map((date, index) => (
          <div
            key={date}
            className={`date-header-cell date-header-cell-${index} ${date === moment().format('YYYY-MM-DD') ? 'today' : ''}`}
          >
            <Moment format="ddd,  MMM D">{date}</Moment>
            {date === moment().format('YYYY-MM-DD') && <span className="today-label">Today</span>}
          </div>
        ))}
      </div>
      <div className="calendar-bdy">
        {loading ? (
          <div className="loading-indicator">
            <div className="spinner"></div>
          </div>
        ) : (
          listings.map(listing => {
            const property = properties.find(p => p.id === listing.calendarPropertyId);
            return (
              <div key={listing.calendarPropertyId} className="calendar-row">
                {dates.map(date => (
                  <DayCell
                    key={date}
                    date={date}
                    listing={listing}
                    property={property}
                    onBookingClick={onBookingClick}
                    onJobClick={onJobClick}
                    onReservationClick={onReservationClick}
                    onJobDetailClick={onJobDetailClick}
                  />
                ))}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
});

export default Calendar;
