import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDollarSign, faBroom, faWrench, faSpinner, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { createJob, fetchAssociatedContractors } from '../../api/request';
import { CreateJobRequest, ContractorsResponse, PropertyList } from '../../api/type';
import moment from 'moment';

import cleaningIcon from '../assets/cleaning-dark.png';
import maintenanceIcon from '../assets/maintenance-dark.png';
import improvementIcon from '../assets/settings.png';
import taskIcon from '../assets/file.png';
import contactIcon from '../assets/phone.png';
import clockIcon from '../assets/clock-b.png';
import xIcon from '../assets/x.png';
import { ImportantOrgContext } from '../../App';
import homeDark from '../assets/home-dark.png';


interface SidebarProps {
  onClose: () => void;
  sideBar: boolean;
  property: PropertyList | undefined;
  selectedDate: string;
}

type JobType = 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';

interface Contractor {
  _id: string;
  'POC: Full Name': string | null;
  'DETAILS: Company Name': string;
}

const getJobIcon = (type: string) => {
  switch (type) {
    case 'Cleaning':
      return cleaningIcon;
    case 'Maintenance':
      return maintenanceIcon;
    case 'Task':
      return taskIcon;
    case 'Improvement':
      return improvementIcon;
    case 'Contact':
      return contactIcon;
    default:
      return improvementIcon;
  }
};

const JobSidebar: React.FC<SidebarProps> = ({ onClose, sideBar, property, selectedDate }) => {
  const [formData, setFormData] = useState({
    propertyListing: property?.id || '',
    selectedJobType: 'Cleaning' as JobType,
    selectedContractor: '',
    startTime: '',
    endTime: '',
    taskTitle: '',
    taskDescription: '',
    rate: '',
    repeat: false,
    repeatInterval: 1,
    repeatUnit: 'DAILY',
    selectedDays: [] as string[],
    ends: 'never',
    endDate: '',
    occurrences: ''
  });

  const importantOrgId = useContext(ImportantOrgContext);

  const [contractors, setContractors] = useState<Contractor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState<boolean>(false);

  const jobTypeIcons: Record<JobType, IconDefinition> = {
    Cleaning: faBroom,
    Maintenance: faWrench,
    Task: faBroom,
    Improvement: faBroom,
    Contact: faBroom
  };

  const daysOfWeek = [
    { full: 'SUNDAY', short: 'S' },
    { full: 'MONDAY', short: 'M' },
    { full: 'TUESDAY', short: 'T' },
    { full: 'WEDNESDAY', short: 'W' },
    { full: 'THURSDAY', short: 'T' },
    { full: 'FRIDAY', short: 'F' },
    { full: 'SATURDAY', short: 'S' },
  ];

  const getInitials = (name: string) => {
    const names = name.split(' ');
    const initials = names.slice(0, 2).map((n) => n[0]).join('');
    return initials;
  };

  const handleDaySelection = (day: string) => {
    setFormData(prevData => ({
      ...prevData,
      selectedDays: prevData.selectedDays.includes(day)
        ? prevData.selectedDays.filter(d => d !== day)
        : [...prevData.selectedDays, day]
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData(prevData => ({
        ...prevData,
        [name]: checked
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.selectedJobType) newErrors.selectedJobType = 'Job Type is required.';
    if (!formData.selectedContractor) newErrors.selectedContractor = 'Contractor is required.';
    if (!formData.startTime) newErrors.startTime = 'Start time is required.';
    if (!formData.endTime) newErrors.endTime = 'End time is required.';
    if (formData.startTime && formData.endTime && formData.startTime >= formData.endTime) {
      newErrors.endTime = 'End time must be after start time.';
    }
    if (!formData.taskTitle) newErrors.taskTitle = 'Task title is required.';
    if (!formData.rate) newErrors.rate = 'Rate is required.';
    if (parseFloat(formData.rate) < 0) newErrors.rate = 'Rate must be a positive number.';
    if (formData.repeat) {
      if (formData.ends === 'on' && !formData.endDate) {
        newErrors.endDate = 'End date is required.';
      } else if (formData.ends === 'after' && !formData.occurrences) {
        newErrors.occurrences = 'Occurrences are required.';
      } else if (formData.ends === 'on' && new Date(formData.endDate) <= new Date(selectedDate)) {
        newErrors.endDate = 'End date must be after the start date.';
      } else if (formData.ends === 'after' && parseInt(formData.occurrences) <= 0) {
        newErrors.occurrences = 'Occurrences must be a positive number.';
      }
      if (formData.repeatUnit === 'WEEKLY' && formData.selectedDays.length === 0) {
        newErrors.selectedDays = 'At least one day must be selected for weekly repeats.';
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toISOString().split('T')[0]; // YYYY-MM-DD format
  };

  const formatTime = (time: string) => {
    const [hourString, minuteString] = time.split(':');
    const hour = parseInt(hourString);
    const minute = parseInt(minuteString);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour}:${minuteString} ${ampm}`; // HH:MM AM/PM format
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccess(false);
    setFormError('');

    if (validateForm()) {
      setLoading(true);
      try {
        const contractor = contractors.find(c => c._id === formData.selectedContractor);
        const jobDetails: CreateJobRequest = {
          id: formData.propertyListing,
          propertyId: formData.propertyListing,
          title: formData.taskTitle,
          description: formData.taskDescription,
          jobType: formData.selectedJobType,
          urgency: "Low",
          bubbleContractorId: contractor ? contractor._id : '',
          importantOrgId: importantOrgId,
          contractorId: contractor ? contractor._id : '',
          startDate: selectedDate || formatDate(new Date().toISOString()), // Use selectedDate or fallback to current date
          startTime: formatTime(formData.startTime), // Format time to HH:MM AM/PM
          endTime: formatTime(formData.endTime), // Format time to HH:MM AM/PM
          repeatEvery: formData.repeat ? formData.repeatUnit.toUpperCase() : "DAILY",
          repeatDaysInWeek: formData.repeat ? formData.selectedDays : [],
          endDate: formData.repeat && formData.ends === 'on' ? formatDate(formData.endDate) : "2024-07-28T21:54:22.620Z",
          totalOccurrences: formData.repeat && formData.ends === 'after' ? parseInt(formData.occurrences) : 1,
          rate: parseFloat(formData.rate as string)
        };

        console.log('Job Details!!!:', jobDetails);

        // Api Call 
        const response = await createJob(jobDetails);
        console.log("job response", response);
        setSuccess(true);

        setFormData({
          propertyListing: property?.id || '',
          selectedJobType: 'Cleaning',
          selectedContractor: contractors[0]?._id || '',
          startTime: '',
          endTime: '',
          taskTitle: '',
          taskDescription: '',
          rate: '',
          repeat: false,
          repeatInterval: 1,
          repeatUnit: 'DAILY',
          selectedDays: [],
          ends: 'never',
          endDate: '',
          occurrences: ''
        });

        // Delay before refreshing
        setTimeout(() => {
          window.location.reload(); // Refresh the page after 2 seconds
        }, 2000);

      } catch (error) {
        console.error('Error submitting the form', error);
        setFormError('Failed to create job. Please try again.');
      } finally {
        setLoading(false);
      }
    } else {
      setFormError('Please correct the errors in the form.');
    }
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else if (formError) {
      const errorTimer = setTimeout(() => {
        setFormError('');
      }, 5000);

      return () => clearTimeout(errorTimer);
    }
  }, [success, formError]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contractorsResponse: ContractorsResponse = await fetchAssociatedContractors(importantOrgId, property?.id || '');
        if (contractorsResponse.data) {
          const contractorsData: Contractor[] = contractorsResponse.data;
          setContractors(contractorsData);
          setFormData((prevData) => ({
            ...prevData,
            selectedContractor: contractorsData[0]?._id || '',
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [property]);

  return (
    <div className={`sidebar ${sideBar ? 'open' : ''}`}>
      <div className="sidebar-header">
        <h2>Create A Job</h2>
        <div className="sidebar-header-icons">
          <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className='x-icon' /></button>
        </div>
      </div>
      {success && (
        <div className="success-message">
          <FontAwesomeIcon icon={faCheckCircle} /> Job successfully created!
        </div>
      )}
      <div className="sidebar-body">
        <form onSubmit={handleSubmit} className="sidebar-content-job">
          <div>
            <div className='job-row'>
              <div className='booking-column'>
                <div className="booking-label">Date</div>
                <div className="booking-value">{moment(selectedDate).format('MMM DD, YYYY')}</div>
              </div>
            </div>
            <div className="task-form px-3">
              <div className="form-group">
                <label htmlFor="property">Property Listing</label>
                <div className='property-row mt-1'>
                  <div className="property-nou">
                    <div className={property?.image ? "listing-image" : "listing-image-none"}>
                      <img src={property?.image || homeDark} alt={property?.name || 'Property'} />
                    </div>
                    <div>
                      <h3>{property?.name}</h3>
                      <p>{property?.address}</p>
                    </div>
                  </div>
                </div>
                <div className='booking-row-second'></div>
              </div>

              <div className="form-group">
                <label htmlFor="jobType">Job Type*</label>
                <div className="custom-select icon-select">
                  <select
                    id="jobType"
                    name="selectedJobType"
                    value={formData.selectedJobType}
                    onChange={handleChange}
                  >
                    <option value="Cleaning">Cleaning</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Task">Task</option>
                    <option value="Improvement">Improvement</option>
                    <option value="Contact">Contact</option>
                  </select>
                  <img src={getJobIcon(formData.selectedJobType)} alt="Job Type Icon" className="job-type-icon" />
                  {errors.selectedJobType && <div className="error-message">{errors.selectedJobType}</div>}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="contractor">Contractor*</label>
                <div className="custom-select initials-select">
                  <select
                    id="contractor"
                    name="selectedContractor"
                    value={formData.selectedContractor}
                    onChange={handleChange}
                  >
                    {contractors.map((contractor) => (
                      <option value={contractor._id} key={contractor._id}>
                        {contractor['POC: Full Name'] || contractor['DETAILS: Company Name']}
                      </option>
                    ))}
                  </select>
                  <div className="contractor-initials">{getInitials(contractors.find(c => c._id === formData.selectedContractor)?.['POC: Full Name'] || '')}</div>
                  {errors.selectedContractor && <div className="error-message">{errors.selectedContractor}</div>}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="time">Start - Finish Time*</label>
                <div className="time-input">
                  <input
                    type="time"
                    id="startTime"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleChange}
                  />
                  <img src={clockIcon} alt="Job Type Icon" className="clock-icon" />
                  <input
                    type="time"
                    id="endTime"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleChange}
                  />
                </div>
                <div className="time-input-error">
                  {errors.startTime && <div className="error-message">{errors.startTime}</div>}
                  {errors.endTime && <div className="error-message">{errors.endTime}</div>}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="rate">Rate*</label>
                <div className="rate-input">
                  <input
                    type="number"
                    id="rate"
                    name="rate"
                    value={formData.rate}
                    onChange={handleChange}
                    placeholder="0"
                    className="no-spinner"
                  />
                </div>
                {errors.rate && <div className="error-message">{errors.rate}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="taskTitle">Task Title*</label>
                <input
                  type="text"
                  id="taskTitle"
                  name="taskTitle"
                  value={formData.taskTitle}
                  onChange={handleChange}
                  placeholder="Enter Task Title"
                />
                {errors.taskTitle && <div className="error-message">{errors.taskTitle}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="taskDescription">Task Description</label>
                <textarea
                  id="taskDescription"
                  name="taskDescription"
                  value={formData.taskDescription}
                  onChange={handleChange}
                  placeholder="Enter Task Description"
                  rows={4}
                ></textarea>
                {errors.taskDescription && <div className="error-message">{errors.taskDescription}</div>}
              </div>

              <div className="form-group">
                <label>Repeat</label>
                <div className="switch-container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="repeat"
                      checked={formData.repeat}
                      onChange={handleChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <span className={`switch-label ${formData.repeat ? 'confirmed' : ''}`}>{formData.repeat ? 'Yes' : 'No'}</span>
                </div>
              </div>

              {formData.repeat && (
                <>
                  <div className="form-group">
                    <label htmlFor="repeatInterval">Repeat every</label>
                    <div className="repeat-every">
                      <input
                        type="number"
                        id="repeatInterval"
                        name="repeatInterval"
                        min="1"
                        value={formData.repeatInterval}
                        onChange={handleChange}
                        style={{ width: '40%' }}
                      />
                      <select
                        id="repeatIntervalUnit"
                        name="repeatUnit"
                        value={formData.repeatUnit}
                        onChange={handleChange}
                      >
                        <option value="DAILY">Daily</option>
                        <option value="WEEKLY">Weekly</option>
                        <option value="MONTHLY">Monthly</option>
                        <option value="YEARLY">Yearly</option>
                      </select>
                    </div>
                    {errors.repeatInterval && <div className="error-message">{errors.repeatInterval}</div>}
                  </div>

                  <div className="form-group">
                    <label>Repeat on</label>
                    <div className="repeat-days">
                      {daysOfWeek.map((day, index) => (
                        <label key={index} className={`day-checkbox ${formData.selectedDays.includes(day.full) ? 'selected' : ''}`}>
                          <input
                            type="checkbox"
                            value={day.full}
                            checked={formData.selectedDays.includes(day.full)}
                            onChange={() => handleDaySelection(day.full)}
                          />
                          {day.short}
                        </label>
                      ))}
                    </div>
                    {errors.selectedDays && <div className="error-message">{errors.selectedDays}</div>}
                  </div>

                  <div className="form-group">
                    <label>Ends</label>
                    <div className="ends-options">
                      <label>
                        <input
                          type="radio"
                          name="ends"
                          value="never"
                          checked={formData.ends === 'never'}
                          onChange={handleChange}
                        />
                        <span className="custom-radio"></span>
                        <span>Never</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="ends"
                          value="on"
                          checked={formData.ends === 'on'}
                          onChange={handleChange}
                        />
                        <span className="custom-radio"></span>
                        <span style={{ minWidth: '40%' }}>On</span>
                        {formData.ends === 'on' && (
                          <input
                            type="date"
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleChange}
                            className="ends-date-input"
                            style={{ width: '50%' }}
                          />
                        )}
                        {errors.endDate && <div className="error-message">{errors.endDate}</div>}
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="ends"
                          value="after"
                          checked={formData.ends === 'after'}
                          onChange={handleChange}
                        />
                        <span className="custom-radio"></span>
                        <span style={{ minWidth: '40%' }}>After</span>
                        {formData.ends === 'after' && (
                          <>
                            <input
                              type="number"
                              name="occurrences"
                              value={formData.occurrences}
                              onChange={handleChange}
                              className="ends-occurrences-input"
                              style={{ width: '50%' }}
                            />
                          </>
                        )}
                        {errors.occurrences && <div className="error-message">{errors.occurrences}</div>}
                      </label>
                    </div>
                  </div>
                </>
              )}

              {formError && (
                <div className="error-message">
                  <FontAwesomeIcon icon={faTimesCircle} /> {formError}
                </div>
              )}

              <div className="modal-footer">
                <div className="form-actions">
                  <button type="submit" className="primary-btn font-500" disabled={loading}>
                    {loading ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin /> Processing...
                      </>
                    ) : (
                      'Create Job'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div >
    </div >
  );
};

export default JobSidebar;
